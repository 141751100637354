import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  styled,
  Typography,
} from '@mui/material';
import { useTranslation } from 'next-i18next';
import React from 'react';

import { ArrowDownBoldIcon } from '@/components/common/Icons/ArrowDownBoldIcon';

import { FooterMobileNavLinks } from './FooterMobileNavLinks';

const StyledArrowDownBoldIcon = styled(ArrowDownBoldIcon)({
  fontSize: 16,
});

const StyledAccordion = styled(Accordion)({
  borderBottom: 0,
  boxShadow: 'none',
  '& .MuiAccordionSummary-content': {
    justifyContent: 'center',
    flexGrow: 0,
  },
});

const StyledAccordionDetails = styled(AccordionDetails)({
  paddingTop: 0,
});

export const FooterMobileMoreDropdown = (): React.ReactElement => {
  const { t } = useTranslation('header_footer');
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      alignContent="center"
    >
      <StyledAccordion square>
        <AccordionSummary
          expandIcon={<StyledArrowDownBoldIcon color="secondary" />}
          aria-controls="footer-accordion"
          id="footer-accordion"
        >
          <Typography variant="body1">{t('more')}</Typography>
        </AccordionSummary>
        <StyledAccordionDetails>
          <FooterMobileNavLinks />
        </StyledAccordionDetails>
      </StyledAccordion>
    </Grid>
  );
};
