import { Grid, styled } from '@mui/material';
import { useTranslation } from 'next-i18next';
import React from 'react';

import { Divider } from '@/components/common/Divider/Divider';
import { EVIcon } from '@/components/common/Icons/EVIcon';

import { getLinkTranslations } from '../common';
import { aboutLink, careerLink, imprintLink, pressLink } from '../footerConfig';
import { FooterContactColumn } from '../FooterContactColumn';
import { FooterMobileBrandRows } from './FooterMobileBrandRows';
import { FooterMobileMainLink } from './FooterMobileLink';
import { FooterMobileMoreDropdown } from './FooterMobileMoreDropdown';

const StyledContainer = styled(Grid)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
}));
const StyledRowContainer = styled(Grid)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));

export const FooterMobile = (): React.ReactElement => {
  const { t } = useTranslation('header_footer');

  const aboutTranslatedLink = getLinkTranslations(aboutLink, t);
  const careerTranslatedLink = getLinkTranslations(careerLink, t);
  const imprintTranslatedLink = getLinkTranslations(imprintLink, t);
  const pressTranslatedLink = getLinkTranslations(pressLink, t);
  return (
    <>
      <Divider />
      <StyledContainer container>
        <StyledRowContainer container justifyContent="space-between">
          <FooterMobileMainLink href={aboutTranslatedLink.href}>
            {aboutTranslatedLink.text}
          </FooterMobileMainLink>
          <FooterMobileMainLink href={careerTranslatedLink.href}>
            {careerTranslatedLink.text}
          </FooterMobileMainLink>
        </StyledRowContainer>
        <Divider />

        <StyledRowContainer container justifyContent="space-between">
          <FooterMobileMainLink href={imprintTranslatedLink.href}>
            {imprintTranslatedLink.text}
          </FooterMobileMainLink>
          <FooterMobileMainLink href={pressTranslatedLink.href}>
            {pressTranslatedLink.text}
          </FooterMobileMainLink>
        </StyledRowContainer>
        <Divider />
      </StyledContainer>
      <StyledContainer container>
        <StyledRowContainer
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <FooterContactColumn />
        </StyledRowContainer>
        <Divider />
        <Grid container justifyContent="space-between">
          <FooterMobileMoreDropdown />
        </Grid>
        <Divider />
        <FooterMobileBrandRows />
      </StyledContainer>
      <Divider spacing={0} />
      <Grid container justifyContent="center">
        <EVIcon />
      </Grid>
    </>
  );
};
