import { SvgIcon } from '@mui/material';
import type { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const ArrowDownBoldIcon = (props: SvgIconProps): React.ReactElement => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="m5.179 8.177 4.353 4.353 4.354-4.353-.707-.707-3.647 3.646L5.886 7.47l-.707.707Z"
        clipRule="evenodd"
      />
    </SvgIcon>
  );
};
